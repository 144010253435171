<template>
  <div class="main">
    <b-row>
      <b-col md="4">
        <div class="form-group">
          <label>Data (de la)</label>
          <flat-pickr
              @input="getOrders"
              :config="dateConfig"
              v-model="filters.from"
              class="form-control"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Data (pana la)</label>
          <flat-pickr
              @input="getOrders"
              :config="dateConfig"
              v-model="filters.to"
              class="form-control"
          />
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Filtru Restaurant</label>
          <b-form-select @input="getOrders" v-model="filters.restaurant_id" :options="restaurantsOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
      <b-col md="4">
        <div class="form-group">
          <label>Status</label>
          <b-form-select @input="getOrders" v-model="filters.status" :options="statusesOptions" size="sm" class="form-control"></b-form-select>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        Numar total de comenzi : <span><strong>{{paging.total}}</strong></span>
      </b-col>
    </b-row>
    <b-row v-if="orders.length > 0">
      <b-col md="12">
        <b-table-simple responsive>
          <b-thead>
            <b-tr>
              <b-th>#ID</b-th>
              <b-th>METODA PLATA</b-th>
              <b-th>DATA</b-th>
              <b-th>CLIENT</b-th>
              <b-th>RESTAURANT</b-th>
              <b-th>VALOARE Comanda</b-th>
              <b-th>TAXA DE LIVRARE</b-th>
              <b-th>STATUS</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="order of orders" :key="order.id">
              <b-td>{{order.id}}</b-td>
              <b-td style="min-width: 140px;">
                <b-alert class="p-1 text-center" v-if="order.paid_with_card" show variant="success">CARD</b-alert>
                <b-alert class="p-1 text-center" v-else show variant="primary">CASH</b-alert>
              </b-td>
              <b-td style="min-width: 180px">{{ formatDateTime(order.created_at) }}</b-td>
              <b-td>{{order.order_details.firstName}} {{order.order_details.lastName}}</b-td>
              <b-td style="min-width: 180px">{{order.restaurant.name}} - {{order.restaurant.city}}</b-td>
              <b-td style="min-width: 200px;" v-if="order.voucher">{{ toCurrency(order.order_total + order.delivery_tax - calculateVoucherAmount(order)) }}</b-td>
              <b-td style="min-width: 200px;" v-else>{{ toCurrency(order.order_total + order.delivery_tax) }}</b-td>
              <b-td style="min-width: 200px;">{{ toCurrency(order.delivery_tax) }}
                <span v-if="order.order_details.extra_km_fixed_price && order.order_details.extra_km_price">
                   ({{ toCurrency(parseFloat(order.order_details.extra_km_fixed_price) + parseFloat(order.order_details.extra_km_price)) }} taxa extra)
                </span>
              </b-td>
              <b-td>{{ orderStatus(order.status) }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
      <b-col md="4">
        <b-button variant="primary" @click="generateReport()">Generare raport PDF</b-button>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert variant="info" show>Nu exista nici o comanda</b-alert>
    </b-row>
  </div>
</template>

<script>
import Orders from '@/components/driverEndpoints/orders'
import Restaurants from '@/components/driverEndpoints/restaurants'
import 'flatpickr/dist/flatpickr.min.css';
import flatPickr from 'vue-flatpickr-component'
const ro = require("flatpickr/dist/l10n/ro.js").default.ro;

export default {
  data() {
    return {
      error: false,
      orders: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      },
      filters: {
        status: '',
        from: '',
        to: '',
        restaurant_id: ''
      },
      restaurants: []
    }
  },
  components: {
    flatPickr
  },
  methods: {
    generateReport() {
      Orders.report(this.filters).then(response => {
        const uid = response.data.uid
        window.open(process.env.VUE_APP_API_URL + '/orders/reports/' + uid, "_blank")
      })
    },
    getOrders() {
      Orders.getAll(this.paging, this.filters).then(response => {
        this.orders = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    getRestaurants() {
      Restaurants.getDriverRestaurants().then(response => {
        this.restaurants = response.data.data
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getOrders()
    }
  },
  mounted() {
    this.getRestaurants()
    this.getOrders()
  },
  computed: {
    statusesOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOATE',
      }
      returnData.push(tmpObj)
      Object.keys(this.orderStatuses).forEach(key => {
        const tmpObj = {
          value: key,
          text: this.orderStatuses[key],
        }
        returnData.push(tmpObj)
      });
      return returnData
    },
    dateConfig() {
      return {
        enableTime: false,
        dateFormat: "Y-m-d",
        altFormat: "d.m.Y",
        altInput: true,
        allowInput: true,
        locale: ro,
      }
    },
    restaurantsOptions() {
      const returnData = []
      const tmpObj = {
        value: '',
        text: 'TOATE RESTAURANTELE',
      }
      returnData.push(tmpObj)
      this.restaurants.forEach(restaurant => {
        const tmpObj = {
          value: restaurant.id,
          text: restaurant.name + ' ' + restaurant.city
        }
        returnData.push(tmpObj)
      })
      return returnData
    }
  }
}
</script>