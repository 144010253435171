<template>
  <div class="main">
    <b-row v-if="orders.length > 0">
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
      <b-col md="6" v-for="(orderData, index) in orders" :key="index">
        <div class="box_order_form">
          <div class="head text-center" :class="orderStatusesBackgroundColors[orderData.status]">
            <h3>#{{ orderData.id }} {{ orderData.restaurant.name }}</h3>
            <h3>{{ orderData.restaurant.street }}</h3>
            <h3>{{ orderData.restaurant.zip }} {{ orderData.restaurant.city }}</h3>
            <h3>Status: {{ orderStatus(orderData.status) }}</h3>
            <b-alert class="p-1 text-center" v-if="orderData.paid_with_card" show variant="success">ACHITATA CU CARD ONLINE</b-alert>
            <b-alert class="p-1 text-center" v-else show variant="primary">SE VA ACHITA CASH</b-alert>
          </div>
          <!-- /head -->
          <div class="main">
            <b-row>
              <b-col md="12" class="mt-2">
                <h5>Produse comandate</h5>
                <ProductsDetails
                  :products=orderData.products 
                />
              </b-col>
              <b-col md="12">
                <div class="detail_page_head pt-4 clearfix text-center">
                  <h6>CLIENT:</h6>
                  <h6>{{ orderData.order_details.firstName }} {{ orderData.order_details.lastName }} </h6>
                  <p>{{ orderData.order_details.street }} {{ orderData.order_details.address_extra }}, {{ orderData.order_details.zip }} {{ orderData.order_details.city }}</p>
                </div>
              </b-col>
              <b-col md="12">
                <div class="detail_page_head pt-4 clearfix text-center">
                  <h6>DATA ESTIMATA DE RIDICARE:</h6>
                  <h6> {{ addMinutesToDate(orderData.created_at, orderData.est_restaurant_time_min) }}</h6>
                </div>
              </b-col>
              <b-col md="12">
                <div class="detail_page_head pt-4 clearfix text-center">
                  <h6 v-if="!orderData.voucher">Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax) }}</h6>
                  <h6 v-if="orderData.voucher">
                    Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax - calculateVoucherAmount(orderData)) }}
                  </h6>
                  <span>Produse: {{ toCurrency(orderData.order_total) }}</span>
                  <p class="mb-0">Livrare: {{ toCurrency(orderData.delivery_tax) }}</p>
                  <p v-if="orderData.voucher && orderData.voucher.value_type === 'fixed'" class="text-danger">
                    Cupon reducere: -{{ toCurrency(orderData.voucher.value) }}
                  </p>
                  <p v-if="orderData.voucher && orderData.voucher.value_type === 'percent'" class="text-danger">
                    Cupon reducere: -{{ toCurrency(calculateVoucherAmount(orderData)) }} ({{ orderData.voucher.value }} %)
                  </p>
                </div>
              </b-col>
              <b-col md="12" class="text-center" v-if="orderData.order_details.customer_notes">
                <h4>Mentiuni comanda</h4>
                <div class="detail_page_head clearfix">
                  <p>{{ orderData.order_details.customer_notes }} </p>
                </div>
              </b-col>
              <b-col md="12">
                <b-button  variant="success" style="width: 100%"><a class="text-white" :href="'tel:'+orderData.restaurant.telefon">Suna la restaurant</a></b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert show variant="info">Nu exista comenzi noi</b-alert>
    </b-row>
  </div>
</template>

<script>
import ProductsDetails from '@/components/shared/productsDetails'
import Orders from '@/components/driverEndpoints/orders'
import { bus } from "../../main";
export default {
  data() {
    return {
      error: false,
      orders: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      },
      filters: {
        status: [0]
      }
    }
  },
  components: {
    ProductsDetails,
  },
  methods: {
    getOrders() {
      Orders.getAllPending(this.paging, this.filters).then(response => {
        this.orders = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getOrders()
    },
  },
  mounted() {
    bus.$on('driver_new_order', () => {
      this.getOrders()
    })
    bus.$on('driver_update_order', () => {
      this.getOrders()
    })
    bus.$on('restaurant_update_order', () => {
      this.getOrders()
    })
    this.getOrders()
  }
}
</script>
