<template>
  <div class="main">
    <b-row v-if="orders.length > 0">
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
      <b-col md="6" v-for="(orderData, index) in orders" :key="index">
        <div class="box_order_form">
          <div class="head text-center" :class="orderStatusesBackgroundColors[orderData.status]">
            <h3>#{{ orderData.id }} {{ orderData.restaurant.name }}</h3>
            <h3>{{ orderData.restaurant.street }}</h3>
            <h3>{{ orderData.restaurant.zip }} {{ orderData.restaurant.city }}</h3>
            <h3>Status: {{ orderStatus(orderData.status) }}</h3>
            <b-alert class="p-1 text-center mt-2" v-if="orderData.preorder_date" show variant="danger"><strong>Precomanda la:</strong> {{orderData.preorder_date}}</b-alert>
            <b-alert class="p-1 text-center" v-if="orderData.paid_with_card" show variant="success">ACHITATA CU CARD ONLINE</b-alert>
            <b-alert class="p-1 text-center" v-else show variant="primary">SE VA ACHITA CASH</b-alert>
          </div>
          <!-- /head -->
          <div class="main">
            <b-row>
              <b-col md="12">
                <div class="detail_page_head pt-4 clearfix text-center">
                  <h6>CLIENT:</h6>
                  <h6>{{ orderData.order_details.firstName }} {{ orderData.order_details.lastName }} </h6>
                  <p>{{ orderData.order_details.street }} {{ orderData.order_details.address_extra }}, {{ orderData.order_details.zip }} {{ orderData.order_details.city }}</p>
                </div>
              </b-col>
              <b-col md="12">
                <div class="detail_page_head pt-4 clearfix text-center">
                  <h6>DATA ESTIMATA DE RIDICARE:</h6>
                  <h6> {{ addMinutesToDate(orderData.created_at, orderData.est_restaurant_time_min) }}</h6>
                </div>
              </b-col>
              <b-col md="12">
                <div class="detail_page_head pt-4 clearfix text-center">
                  <h6 v-if="!orderData.voucher">Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax) }}</h6>
                  <h6 v-if="orderData.voucher">
                    Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax - calculateVoucherAmount(orderData)) }}
                  </h6>
                  <span>Produse: {{ toCurrency(orderData.order_total) }}</span>
                  <p class="mb-0">Livrare: {{ toCurrency(orderData.delivery_tax) }}</p>
                  <p v-if="orderData.voucher && orderData.voucher.value_type === 'fixed'" class="text-danger">
                    Cupon reducere: -{{ toCurrency(orderData.voucher.value) }}
                  </p>
                  <p v-if="orderData.voucher && orderData.voucher.value_type === 'percent'" class="text-danger">
                    Cupon reducere: -{{ toCurrency(calculateVoucherAmount(orderData)) }} ({{ orderData.voucher.value }} %)
                  </p>
                </div>
              </b-col>
              <b-col md="12" class="text-center" v-if="orderData.order_details.customer_notes">
                <h4>Mentiuni comanda</h4>
                <div class="detail_page_head clearfix">
                  <p>{{ orderData.order_details.customer_notes }} </p>
                </div>
              </b-col>
              <b-col md="12">
                <b-button  variant="success" style="width: 100%" @click="acceptOrder(orderData)">ACCEPTA COMANDA</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert show variant="info">Nu exista comenzi noi</b-alert>
    </b-row>
  </div>
</template>

<script>
import Orders from '@/components/driverEndpoints/orders'
import { bus } from "../../main";
export default {
  data() {
    return {
      error: false,
      orders: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      },
      filters: {
        status: [1, 2]
      }
    }
  },
  methods: {
    getOrders() {
      Orders.getAllNew(this.paging, this.filters).then(response => {
        this.orders = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getOrders()
    },
    async acceptOrder(order) {
      const updateData = {
        accept: true
      }
      const {value: timp} = await this.$swal.fire({
        title: 'Doresti sa accepti aceasta comanda?',
        text: 'Comanda va fi apoi afisata in sectiunea Comenzi de preluat. Selecteaza timpul in care estimezi ca poti ajunge la restaurant',
        icon: 'warning',
        input: 'select',
        confirmButtonText: 'DA, ACCEPT COMANDA',
        cancelButtonText: 'NU, RENUNTA',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        inputOptions: {
          '5': '5 Minute',
          '10': '10 Minute',
          '15': '15 Minute',
          '20': '20 Minute',
          '30': '30 Minute',
          '40': '40 Minute',
          '60': '1 ORA',
          '90': '1 ORA si 30 MIN',
          '120': '2 ORE',
        },
        inputValue: '30',
        inputPlaceholder: '',
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            resolve()
          })
        }
      })
      if (!timp) {
        return
      }
      updateData.est_driver_time_to_restaurant_min = parseInt(timp)
      Orders.update(order.id, updateData).then(() => {
        this.getOrders()
      }).catch(error => {
        this.$toastr.e(error.response.data.message);
      })
    },
    changeOrderStatus(order, newStatus) {
      const updateData = {
        status: newStatus
      }
      if(newStatus === 5) {
        this.$swal({
          title: 'Doresti sa anulezi comanda?',
          text: 'Comanda va fi anulata. O poti revedea in istoric comenzi',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'DA, ANULEAZA COMANDA',
          cancelButtonText: 'NU, RENUNTA',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            Orders.update(order.id, updateData).then(() => {
              this.getOrders()
            }).catch(error => {
              this.$toastr.e(error.response.data.message);
            })
          }
        })
        return
      }
      Orders.update(order.id, updateData).then(() => {
        this.getOrders()
      }).catch(error => {
        this.$toastr.e(error.response.data.message);
      })
    }
  },
  mounted() {
    bus.$on('driver_update_order', () => {
      this.getOrders()
    })
    this.getOrders()
  }
}
</script>