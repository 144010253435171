<template>
  <main class="bg_gray" style="padding-top: 150px;">
    <b-container>
      <b-row>
        <b-col md="3">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>MENIU ADMINISTRARE</h3>
            </div>
            <div class="impersonated" v-if="impersonatedUsers">
              <b-row>
                <b-col md="12" class="p-1" v-for="(user, index) in impersonatedUsers" :key="index">
                  <b-button @click="backToUser(user)" block class="w-100" variant="primary"><i class="mdi mdi-exit-run"></i> {{ user.user_data.email }}</b-button>
                </b-col>
              </b-row>
            </div>
            <!-- /head -->
            <div class="main">
              <div>
                <b-nav pills vertical class="text-center">
                  <b-nav-item :active="$route.name === 'driver.dashboard'" :to="{ name: 'driver.dashboard' }">Dashboard</b-nav-item>
                  <b-nav-item :active="$route.name === 'driver.orders.pending'" :to="{ name: 'driver.orders.pending' }">Comenzi neprocesate</b-nav-item>
                  <b-nav-item :active="$route.name === 'driver.orders.new'" :to="{ name: 'driver.orders.new' }">Comenzi noi</b-nav-item>
                  <b-nav-item :active="$route.name === 'driver.orders.pickup'" :to="{ name: 'driver.orders.pickup' }">Comenzi de preluat</b-nav-item>
                  <b-nav-item :active="$route.name === 'driver.orders.delivery'" :to="{ name: 'driver.orders.delivery' }">Comenzi de livrat</b-nav-item>
                  <b-nav-item :active="$route.name === 'driver.orders'" :to="{ name: 'driver.orders' }">Istoric comenzi</b-nav-item>
                </b-nav>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'driver.dashboard'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>DASHBOARD</h3>
            </div>
            <!-- /head -->
            <Statistics />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'driver.orders.pending'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>COMENZI NEPROCESATE</h3>
            </div>
            <PendingOrders />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'driver.orders.new'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>COMENZI NOI</h3>
            </div>
            <NewOrders />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'driver.orders.pickup'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>COMENZI DE PRELUAT</h3>
            </div>
            <PickupOrders />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'driver.orders.delivery'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>COMENZI DE LIVRAT</h3>
            </div>
            <DeliveryOrders />
          </div>
        </b-col>
        <b-col md="9" v-if="$route.name === 'driver.orders'">
          <div class="box_order_form">
            <div class="head text-center">
              <h3>ISTORIC COMENZI</h3>
            </div>
            <Orders />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import Statistics from '@/views/Driver/Statistics'
import Orders from '@/views/Driver/Orders'
import NewOrders from '@/views/Driver/NewOrders'
import PendingOrders from '@/views/Driver/PendingOrders'
import PickupOrders from '@/views/Driver/PickupOrders'
import DeliveryOrders from '@/views/Driver/DeliveryOrders'
import {bus} from "../../main";

export default {
  components: {
    Statistics,
    NewOrders,
    PendingOrders,
    PickupOrders,
    DeliveryOrders,
    Orders
  },
  computed: {
    impersonatedUsers() {
      let impersonatedUsers = localStorage.getItem('impersonated');
      if (impersonatedUsers) {
        return JSON.parse(impersonatedUsers)
      }
      return false;
    },
  },
  methods: {
    backToUser(user) {
      localStorage.setItem('token', user.token)
      localStorage.setItem('userInfo', JSON.stringify(user.user_data))
      if (user.user_data.role === 'restaurant-manager') {
        this.$router.push({name: 'restaurant-manager.dashboard'})
      } else if (user.user_data.role === 'city-manager') {
        let impersonatedUsers = JSON.parse(localStorage.getItem('impersonated'));
        let newImpersonated = [];
        impersonatedUsers.forEach(user => {
          if (user.user_data.role !== 'city-manager') {
            newImpersonated.push(user);
          }
        })
        localStorage.setItem('impersonated', JSON.stringify(newImpersonated))
        this.$router.push({name: 'city-manager.dashboard'})
      } else if (user.user_data.role === 'driver') {
        this.$router.push({name: 'driver.dashboard'})
      } else if (user.user_data.role === 'customer') {
        this.$router.push({name: 'customer.account'})
      } else if (user.user_data.role === 'administrator') {
        this.$router.push({name: 'admin.dashboard'})
        localStorage.removeItem('impersonated')
      }
      bus.$emit('loggedIn', user.user_data);
    }
  }
}
</script>