<template>
  <div class="main">
    <b-row v-if="orders.length > 0">
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
      <b-col md="6" v-for="(orderData, index) in orders" :key="index">
        <div class="box_order_form">
          <div class="head text-center bg-secondary">
            <h3>#{{ orderData.id }} {{ orderData.restaurant.name }}</h3>
            <h3>{{ orderData.restaurant.street }}</h3>
            <h3>{{ orderData.restaurant.zip }} {{ orderData.restaurant.city }}</h3>
            <b-alert class="p-1 text-center mt-2" v-if="orderData.preorder_date" show variant="danger"><strong>Precomanda la:</strong> {{orderData.preorder_date}}</b-alert>
            <b-alert class="p-1 text-center" v-if="orderData.paid_with_card" show variant="success">ACHITATA CU CARD ONLINE</b-alert>
            <b-alert class="p-1 text-center" v-else show variant="primary">SE VA ACHITA CASH</b-alert>
          </div>
          <!-- /head -->
          <div class="main">
            <b-row>
              <b-row v-if="orderData.preorder_date === null">
                <b-col md="12">
                  <div class="detail_page_head pt-4 clearfix text-center">
                    <h6>DATA ESTIMATA LA CARE AJUNGI:</h6>
                    <h6> {{ addMinutesToDate(orderData.created_at, orderData.est_driver_time_to_customer_min) }}</h6>
                  </div>
                </b-col>
                  <b-col md="12">
                    <div class="detail_page_head pt-4 clearfix text-center">
                      <h5>Modifica timpul estimat</h5>
                    </div>
                  </b-col>
                  <b-col md="6" class="p-2">
                    <b-button @click="addTime(orderData, -10)" style="width: 100%;" variant="primary">-10 Minute</b-button>
                  </b-col>
                  <b-col md="6" class="p-2">
                    <b-button @click="addTime(orderData, 10)" style="width: 100%;" variant="primary">+10 Minute</b-button>
                  </b-col>
                  <b-col md="6" class="p-2">
                    <b-button @click="addTime(orderData, -20)" style="width: 100%;" variant="primary">-20 Minute</b-button>
                  </b-col>
                  <b-col md="6" class="p-2">
                    <b-button @click="addTime(orderData, 20)" style="width: 100%;" variant="primary">+20 Minute</b-button>
                  </b-col>
                  <b-col md="6" class="p-2">
                    <b-button @click="addTime(orderData, -30)" style="width: 100%;" variant="primary">-30 Minute</b-button>
                  </b-col>
                  <b-col md="6" class="p-2">
                    <b-button @click="addTime(orderData, 30)" style="width: 100%;" variant="primary">+30 Minute</b-button>
                  </b-col>
                </b-row>

              <b-col cols="12">
                <div class="detail_page_head pt-4 clearfix text-center">
                  <h5>Detalii livrare</h5>
                  <h6>{{ orderData.order_details.firstName }} {{ orderData.order_details.lastName }}</h6>
                  <h6>{{ orderData.order_details.street }} {{ orderData.order_details.address_extra }}, {{ orderData.order_details.zip }} {{ orderData.order_details.city }}</h6>
                  <h6>
                    <a :href="'tel:' + orderData.order_details.phone">{{ orderData.order_details.phone }}</a>
                  </h6>
                  <b-button variant="success" @click="navigateToCustomer(orderData)">NAVIGARE LA CLIENT</b-button>
                </div>
              </b-col>
              <b-col cols="12" class="text-center pt-2" v-if="orderData.order_details.customer_notes">
                <h4>Mentiuni comanda</h4>
                <div class="detail_page_head clearfix">
                  <p>{{ orderData.order_details.customer_notes }} </p>
                </div>
              </b-col>
              <b-col md="12">
                <div class="detail_page_head pt-2 clearfix text-center">
                  <h6 v-if="!orderData.voucher">Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax) }}</h6>
                  <h6 v-if="orderData.voucher">
                    Total de plata: {{ toCurrency(orderData.order_total + orderData.delivery_tax - calculateVoucherAmount(orderData)) }}
                  </h6>
                  <span>Produse: {{ toCurrency(orderData.order_total) }}</span>
                  <p class="mb-0">Livrare: {{ toCurrency(orderData.delivery_tax) }}</p>
                  <p v-if="orderData.voucher && orderData.voucher.value_type === 'fixed'" class="text-danger">
                    Cupon reducere: -{{ toCurrency(orderData.voucher.value) }}
                  </p>
                  <p v-if="orderData.voucher && orderData.voucher.value_type === 'percent'" class="text-danger">
                    Cupon reducere: -{{ toCurrency(calculateVoucherAmount(orderData)) }} ({{ orderData.voucher.value }} %)
                  </p>
                </div>
              </b-col>
              <b-col md="12" class="mt-2">
                <h5>Produse comandate</h5>
                <ProductsDetails
                  :products=orderData.products 
                />
              </b-col>
              <b-col md="12">
                <b-button  variant="success" style="width: 100%" @click="changeOrderStatus(orderData, 4)">COMANDA A FOST LIVRATA</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12">
        <b-pagination
            v-if="orders.length > 0"
            v-model="paging.page"
            :total-rows="paging.total"
            :per-page="paging.per_page"
            @input="changePage"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-alert show variant="info">Nu exista comenzi de livrat</b-alert>
    </b-row>
  </div>
</template>

<script>
import ProductsDetails from '@/components/shared/productsDetails'
import Orders from '@/components/driverEndpoints/orders'
import {bus} from "../../main";
export default {
  data() {
    return {
      error: false,
      orders: [],
      paging: {
        page: 1,
        per_page: 10,
        total: 10
      }
    }
  },
  components: {
    ProductsDetails
  },
  methods: {
    addTime(order, minutes) {
      const updateData = {
        est_driver_time_to_customer_min: minutes
      }
      Orders.update(order.id, updateData).then(() => {
        this.$toastr.s('Timpul a fost modificat');
      }).catch(error => {
        this.$toastr.e(error.response.data.message);
      })
    },
    navigateToCustomer(order) {
      let address = order.order_details.street;
      if (order.order_details.zip) {
        address += ' ' + order.order_details.zip
      }
      if (order.order_details.city) {
        address += ' ' + order.order_details.city
      }
      window.open('https://maps.google.com/?q=' + address)
    },
    getOrders() {
      const filters = null
      Orders.getAllDelivery(this.paging, filters).then(response => {
        this.orders = response.data.data
        this.paging.total = response.data.meta.total
      })
    },
    changePage(page) {
      this.paging.page = page
      this.getOrders()
    },
    changeOrderStatus(order, newStatus) {
      const updateData = {
        status: newStatus
      }
      if(newStatus === 4) {
        this.$swal({
          title: 'Clientul a primit comanda?',
          text: 'Comanda va fi setata ca livrata. O poti revedea in istoric comenzi',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'DA, CLIENTUL A PRIMIT COMANDA',
          cancelButtonText: 'NU, RENUNTA',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            Orders.update(order.id, updateData).then(() => {
              this.getOrders()
            }).catch(error => {
              this.$toastr.e(error.response.data.message);
            })
          }
        })
      }
    }
  },
  mounted() {
    bus.$on('driver_update_order', () => {
      this.getOrders()
    })
    this.getOrders()
  },
  beforeDestroy() {
    bus.$off("driver_update_order");
  },
}
</script>
